import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=dec6e162&scoped=true"
import script from "./_slug.vue?vue&type=script&lang=js"
export * from "./_slug.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dec6e162",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNavBar: require('/home/cb3-be/components/global/TopNavBar.vue').default})
