
  export default {
    name: "Slug",
    layout: "customPage",
    head() {
      return {
        title: this.network?.name ? this.network.name : 'Booking Engine',
        meta: this.page.seo || [],
      };
    },
    computed: {
      page() {
        return this.network.pages.filter(page => page.slug === this.$route.params.slug)[0] || {};
      },
      getHeaderStyle() {
        let image =
          "https://images.unsplash.com/photo-1469854523086-cc02fe5d8800?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80";

        if (!!this.network?.images?.length) {
          image = this.network.images[0].sizes_full_path.high;
        }

        return `
          background-image: url(${image});
          background-position: center center;
          background-repeat: no-repeat;
          background-attachment: scoll;
          background-size: cover;
          background-color: #999;`;
      },
    }
  }
